<template>
  <vue-final-modal class="modal-pwa-not-available">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getLabel('title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('pwa-not-available')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="not-available__content">
        <atomic-icon filled id="icon-big-error" class="not-available__icon" />
        <div class="not-available__header">
          {{ getLabel('header') }}
        </div>
        <div class="not-available__description">
          {{ getLabel('description') }}
        </div>
        <button-base
          class="not-available__button"
          type="secondary-1"
          size="md"
          @click="closeModal('pwa-not-available')"
          >{{ getLabel('closeButton') }}</button-base
        >
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData?: Maybe<CIModalsContent['pwaNotAvailable']>;
    defaultLocaleData?: Maybe<CIModalsContent['pwaNotAvailable']>;
  }>();
  const { getContent } = useProjectMethods();

  const getLabel = (path: string): string => getContent(props.currentLocaleData, props.defaultLocaleData, path);

  const { closeModal } = useModalStore();
</script>
<style lang="scss" src="~/assets/styles/components/modal/pwa-not-available.scss" />
